<template>
  <div>
    <img class="vertical_align" width="36" src="@/assets/icons/arrow.png" />
    <div class="d-inline ml-2 vertical_align">
      <span class="mb-5"><b>Contact</b></span>
    </div>
    <p>
      <u>
        <a href="mailto:alejandro@animaventures.com"
          >alejandro@animaventures.com</a
        >
      </u>
    </p>
    <!-- <v-form id="contact_form" ref="form">
      <v-text-field
        v-model="subject"
        :rules="subjectRules"
        label="Asunto"
        required
      ></v-text-field>
      <v-textarea
        v-model="textarea"
        :rules="textareaRules"
        rows="2"
        cols="50"
        outlined
        class=" mt-1"
      ></v-textarea
      ><br />
      <v-btn
        class="btn_contact mt-n12 py-1 px-5"
        color="#000000"
        @click="validate"
      >
        Enviar
      </v-btn>
    </v-form> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: "events",
  data: () => ({
    subject: "",
    subjectRules: [v => !!v || "Debes rellenar este campo"],
    textarea: "",
    textareaRules: [v => !!v || "Debes rellenar este campo"]
  }),
  methods: {
    ...mapMutations(['changeToSecondPage']),
    validate() {
      if (this.$refs.form.validate()) {
        window.location.href =
          "mailto:alejandro@animasoluciones.es" +
          "?subject=" +
          this.subject +
          "&body=" +
          this.textarea;
        this.$refs.form.reset();
      }
    }
  },
  mounted() {
    this.changeToSecondPage(true);
  },
};
</script>

<style>
.text_area {
  border: 1px solid #000000;
}

.btn_contact {
  border: 1px #000000;
  border-radius: 28px;
  color: white !important;
  width: inherit;
}

.vertical_align {
  vertical-align: middle;
}
</style>
