<template>
  <div>
    <v-app-bar app height="35" color="#000000" dark>
      <v-row id="wrapp_navbar_lahistoriademicrowd">
        <v-col class="d-none d-md-block">
          <div class="d-md-flex justify-space-between">
              <a href="https://www.microwd.es/" target="_blank">
                <h1 class="mr-2"><b><u>Microwd</u></b></h1>
              </a>
          </div>
        </v-col>
        <v-col class="text-right text-md-right mt-1">
          <span id="text_nav">aledeleonmoreno.com/#/lahistoriademicrowd</span>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "navbar"
};
</script>

<style scoped>
@font-face {
  font-family: "montserrat-regular";
  src: url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

* {
  font-family: montserrat-regular;
}

h1 {
  color: #00f9ad;
  font-size: 18px
}

#text_nav {
  font-size: 12px;
}

@media only screen and (min-width: 960px) {
  #wrapp_navbar_lahistoriademicrowd {
    max-width: 72%;
    margin-left: auto;
    margin-right: auto;
  }

  #text_nav {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1264px) {
  #text_nav {
    font-size: 18px;
  }
}
</style>
