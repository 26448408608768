<template>
  <div id="wrapp_lahistoriademicrowd">
    <navbar></navbar>
    <v-row class="wrapp_chapters">
      <v-col class="pb-0" cols="12" md="6">
        <h2 class="mt-12 text_regular titles"></h2>
        <span class="text_regular subtitles"><span class="text_extrabold">*Haz click</span> sobre las páginas para aumentar.</span>
      </v-col>
      <v-col class="d-flex align-center mt-md-7" cols="12" md="6">        
        <v-select
          v-model="select"
          :items="chapters"
          item-text="name"
          color="#00f9ad"
          :menu-props="{ offsetY: true }"
          return-object
          label="Selecciona un capítulo"
        ></v-select>
      </v-col>
      <v-col class="pt-0" cols="12">
        <v-row>
          <v-col cols="12" md="4">
            <img :src="getImgUrl(`portada.jpg`)" @click="showMultiple(0)" alt="Portada del libro LahistoriadeMicrowd" class="mr-md-5" />
          </v-col>
          <v-col v-for="index in range(select.chapter_page_start, (select.chapter_page_start + select.chapter_pages))" :key="index" cols="12" md="4">
            <img :src="getImgUrl(`page${ index }.jpg`)" @click="showMultiple(index+1)" alt="Página del libro LahistoriadeMicrowd" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-footer
      absolute
      color="#fff"
    >
      <!-- Begin Mailchimp Signup Form -->
        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">
        <div id="mc_embed_signup">
          <form action="https://microwd.us11.list-manage.com/subscribe/post?u=da8256f2f052ce3a2a36115e3&amp;id=e789223f4e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate text-left text-md-center" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">	<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Suscríbete a la #lahistoriadeMicrowd" required>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_da8256f2f052ce3a2a36115e3_e789223f4e" tabindex="-1" value=""></div>
            <div class="clear ml-md-2"><input type="submit" value="Enviar" name="subscribe" id="mc-embedded-subscribe" class="button text_extrabold"></div>
            </div>
          </form>
        </div>
      <!--End mc_embed_signup-->
    </v-footer>
    <!-- LIGHTBOX -->
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import navbar from "../components/lahistoriademicrowd/Navbar";
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: "lahistoriademicrowd",
  data: () => ({
    imgs: '',
    visible: false,
    index: 0,   // default: 0
    select: { chapter_page_start: 7, chapter_pages: 1 },
    chapters: [
      {
        name: 'Todos',
        chapter_page_start: 0,
        chapter_pages: 8
      },
      {
        name: 'Capítulo 1',
        chapter_page_start: 0,
        chapter_pages: 1
      },
      {
        name: 'Capítulo 2',
        chapter_page_start: 2,
        chapter_pages: 2
      },
      {
        name: 'Capítulo 3',
        chapter_page_start: 5,
        chapter_pages: 1
      },
      {
        name: 'Capítulo 4',
        chapter_page_start: 7,
        chapter_pages: 1
      },
      // {
      //   name: 'Capítulo 5',
      //   chapter_page_start: 9,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 6',
      //   chapter_page_start: 11,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 7',
      //   chapter_page_start: 13,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 8',
      //   chapter_page_start: 15,
      //   chapter_pages: 2
      // },
      // {
      //   name: 'Capítulo 9',
      //   chapter_page_start: 18,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 10',
      //   chapter_page_start: 20,
      //   chapter_pages: 2
      // },
      // {
      //   name: 'Capítulo 11',
      //   chapter_page_start: 23,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 12',
      //   chapter_page_start: 25,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 13',
      //   chapter_page_start: 27,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 14',
      //   chapter_page_start: 29,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 15',
      //   chapter_page_start: 31,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 16',
      //   chapter_page_start: 33,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 17',
      //   chapter_page_start: 35,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 18',
      //   chapter_page_start: 37,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 19',
      //   chapter_page_start: 39,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 20',
      //   chapter_page_start: 41,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 21',
      //   chapter_page_start: 43,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 22',
      //   chapter_page_start: 45,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 23',
      //   chapter_page_start: 47,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 24',
      //   chapter_page_start: 49,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 25',
      //   chapter_page_start: 51,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 26',
      //   chapter_page_start: 53,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 27',
      //   chapter_page_start: 55,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 28',
      //   chapter_page_start: 57,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 29',
      //   chapter_page_start: 59,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 30',
      //   chapter_page_start: 61,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 31',
      //   chapter_page_start: 63,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 32',
      //   chapter_page_start: 65,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 33',
      //   chapter_page_start: 67,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 34',
      //   chapter_page_start: 69,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 35',
      //   chapter_page_start: 71,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 36',
      //   chapter_page_start: 73,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 37',
      //   chapter_page_start: 75,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 38',
      //   chapter_page_start: 77,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 39',
      //   chapter_page_start: 79,
      //   chapter_pages: 1
      // },
      // {
      //   name: 'Capítulo 40',
      //   chapter_page_start: 81,
      //   chapter_pages: 1
      // },
    ]
  }),
  components: {
    navbar,
    VueEasyLightbox
  },
  mounted() {
    this.changeToSecondPage(false);
    this.changeIcon();
  },
  methods: {
    ...mapMutations(["changeToSecondPage"]),
    getImgUrl(pic) {
      return require("@/assets/lahistoriademicrowd/" + pic);
    },
    // ------ Vue lightbox methods ------
    showSingle() {
      this.imgs = 'http://via.placeholder.com/350x150'
      // or
      this.imgs = { title: 'this is a placeholder', src: 'http://via.placeholder.com/350x150' }
      this.show()
    },
    showMultiple(start) {
      this.imgs = [
        { title: 'Portada', src: this.getImgUrl(`portada.jpg`) },
        { title: 'Página 1', src: this.getImgUrl(`page0.jpg`) },
        { title: 'Página 2', src: this.getImgUrl(`page1.jpg`) },
        { title: 'Página 3', src: this.getImgUrl(`page2.jpg`) },
        { title: 'Página 4', src: this.getImgUrl(`page3.jpg`) },
        { title: 'Página 5', src: this.getImgUrl(`page4.jpg`) },
        { title: 'Página 6', src: this.getImgUrl(`page5.jpg`) },
        { title: 'Página 7', src: this.getImgUrl(`page6.jpg`) },
        { title: 'Página 8', src: this.getImgUrl(`page7.jpg`) },
        { title: 'Página 9', src: this.getImgUrl(`page8.jpg`) },
        // { title: 'Página 10', src: this.getImgUrl(`page9.jpg`) },
        // { title: 'Página 11', src: this.getImgUrl(`page10.jpg`) },
        // { title: 'Página 12', src: this.getImgUrl(`page11.jpg`) },
        // { title: 'Página 13', src: this.getImgUrl(`page12.jpg`) },
        // { title: 'Página 14', src: this.getImgUrl(`page13.jpg`) },
        // { title: 'Página 15', src: this.getImgUrl(`page14.jpg`) },
        // { title: 'Página 16', src: this.getImgUrl(`page15.jpg`) },
        // { title: 'Página 17', src: this.getImgUrl(`page16.jpg`) },
        // { title: 'Página 18', src: this.getImgUrl(`page17.jpg`) },
        // { title: 'Página 19', src: this.getImgUrl(`page18.jpg`) },
        // { title: 'Página 20', src: this.getImgUrl(`page19.jpg`) },
        // { title: 'Página 21', src: this.getImgUrl(`page20.jpg`) },
        // { title: 'Página 22', src: this.getImgUrl(`page21.jpg`) },
        // { title: 'Página 23', src: this.getImgUrl(`page22.jpg`) },
        // { title: 'Página 24', src: this.getImgUrl(`page23.jpg`) },
        // { title: 'Página 25', src: this.getImgUrl(`page24.jpg`) },
        // { title: 'Página 26', src: this.getImgUrl(`page25.jpg`) },
        // { title: 'Página 27', src: this.getImgUrl(`page26.jpg`) },
        // { title: 'Página 28', src: this.getImgUrl(`page27.jpg`) },
        // { title: 'Página 29', src: this.getImgUrl(`page28.jpg`) },
        // { title: 'Página 30', src: this.getImgUrl(`page29.jpg`) },
        // { title: 'Página 31', src: this.getImgUrl(`page30.jpg`) },
        // { title: 'Página 32', src: this.getImgUrl(`page31.jpg`) },
        // { title: 'Página 33', src: this.getImgUrl(`page32.jpg`) },
        // { title: 'Página 34', src: this.getImgUrl(`page33.jpg`) },
        // { title: 'Página 35', src: this.getImgUrl(`page34.jpg`) },
        // { title: 'Página 36', src: this.getImgUrl(`page35.jpg`) },
        // { title: 'Página 37', src: this.getImgUrl(`page36.jpg`) },
        // { title: 'Página 38', src: this.getImgUrl(`page37.jpg`) },
        // { title: 'Página 39', src: this.getImgUrl(`page38.jpg`) },
        // { title: 'Página 40', src: this.getImgUrl(`page39.jpg`) },
        // { title: 'Página 41', src: this.getImgUrl(`page40.jpg`) },
        // { title: 'Página 42', src: this.getImgUrl(`page41.jpg`) },
        // { title: 'Página 43', src: this.getImgUrl(`page42.jpg`) },
        // { title: 'Página 44', src: this.getImgUrl(`page43.jpg`) },
        // { title: 'Página 45', src: this.getImgUrl(`page44.jpg`) },
        // { title: 'Página 46', src: this.getImgUrl(`page45.jpg`) },
        // { title: 'Página 47', src: this.getImgUrl(`page46.jpg`) },
        // { title: 'Página 48', src: this.getImgUrl(`page47.jpg`) },
        // { title: 'Página 49', src: this.getImgUrl(`page48.jpg`) },
        // { title: 'Página 50', src: this.getImgUrl(`page49.jpg`) },
        // { title: 'Página 51', src: this.getImgUrl(`page50.jpg`) },
        // { title: 'Página 52', src: this.getImgUrl(`page51.jpg`) },
        // { title: 'Página 53', src: this.getImgUrl(`page52.jpg`) },
        // { title: 'Página 54', src: this.getImgUrl(`page53.jpg`) },
        // { title: 'Página 55', src: this.getImgUrl(`page54.jpg`) },
        // { title: 'Página 56', src: this.getImgUrl(`page55.jpg`) },
        // { title: 'Página 57', src: this.getImgUrl(`page56.jpg`) },
        // { title: 'Página 58', src: this.getImgUrl(`page57.jpg`) },
        // { title: 'Página 59', src: this.getImgUrl(`page58.jpg`) },
        // { title: 'Página 60', src: this.getImgUrl(`page59.jpg`) },
        // { title: 'Página 61', src: this.getImgUrl(`page60.jpg`) },
        // { title: 'Página 62', src: this.getImgUrl(`page61.jpg`) },
        // { title: 'Página 63', src: this.getImgUrl(`page62.jpg`) },
        // { title: 'Página 64', src: this.getImgUrl(`page63.jpg`) },
        // { title: 'Página 65', src: this.getImgUrl(`page64.jpg`) },
        // { title: 'Página 66', src: this.getImgUrl(`page65.jpg`) },
        // { title: 'Página 67', src: this.getImgUrl(`page66.jpg`) },
        // { title: 'Página 68', src: this.getImgUrl(`page67.jpg`) },
        // { title: 'Página 69', src: this.getImgUrl(`page68.jpg`) },
        // { title: 'Página 70', src: this.getImgUrl(`page69.jpg`) },
        // { title: 'Página 71', src: this.getImgUrl(`page70.jpg`) },
        // { title: 'Página 72', src: this.getImgUrl(`page71.jpg`) },
        // { title: 'Página 73', src: this.getImgUrl(`page72.jpg`) },
        // { title: 'Página 74', src: this.getImgUrl(`page73.jpg`) },
        // { title: 'Página 75', src: this.getImgUrl(`page74.jpg`) },
        // { title: 'Página 76', src: this.getImgUrl(`page75.jpg`) },
        // { title: 'Página 77', src: this.getImgUrl(`page76.jpg`) },
        // { title: 'Página 78', src: this.getImgUrl(`page77.jpg`) },
        // { title: 'Página 79', src: this.getImgUrl(`page78.jpg`) },
        // { title: 'Página 80', src: this.getImgUrl(`page79.jpg`) },
        // { title: 'Página 81', src: this.getImgUrl(`page80.jpg`) },
        // { title: 'Página 82', src: this.getImgUrl(`page81.jpg`) },
        // { title: 'Página 83', src: this.getImgUrl(`page82.jpg`) },
      ]
      // allow mixing

      this.index = start  // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    changeIcon() {
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.getImgUrl('favicon.jpg');
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
  }
};
</script>

<style scoped>
/* start fonts lahistoriademicrowd */
@font-face {
  font-family: "montserrat-regular";
  src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "montserrat-bold";
  src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "montserrat-extrabold";
  src: url("../assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

.text_regular {
  font-family: "montserrat-regular";
}

.text_bold {
  font-family: "montserrat-bold";
}

.text_extrabold {
  font-family: "montserrat-extrabold";
}

::v-deep .v-select__slot * {
  font-family: "montserrat-bold" !important;
}

::v-deep .v-list-item__content * {
  font-family: "montserrat-bold" !important;
}
/* end fonts lahistoriademicrowd */

.wrapp_chapters {
  margin-bottom: 90px !important;
}

h2 {
  color: #00f9ad;

}

.titles {
  font-size: 5vw;
}

.subtitles {
  font-size: 4vw;
}

img {
  width: inherit;
  cursor: pointer;
}

#wrapp_lahistoriademicrowd {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

/* NEWSLETTER STYLES */
#mc_embed_signup{
  background: #fff; 
  clear:left; font: 14px Helvetica,Arial,sans-serif; 
  width: 100%;
}

#mc_embed_signup input.email {
  border-top: #000000;
  border-left: #000000;
  border-right: #000000;
  width: 280px;
}

#mc_embed_signup .button {
  background-color: #000000;
  color: #00f9ad;
}

@media only screen and (min-width: 960px) {
  #wrapp_lahistoriademicrowd {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .titles {
    font-size: 2.5vw;
  }

  .subtitles {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 960px) {
  #mc_embed_signup input.email {
    width: 100%;
  }

  #mc_embed_signup .button {
    padding: 0 10px;
    font-size: 10px;
  }
}
</style>
